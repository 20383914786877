import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Mulish } from 'next/font/google';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';

import '@/styles/globals.css';

const mulish = Mulish({
  subsets: ['latin'],
  variable: '--font-mulish',
});

const Gleap = dynamic(() => import('../components/Gleap'));

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <Gleap />
      <main className={`${mulish.variable} font-sans`}>
        {/* TODO: eslint disabled here, cause we don't know exactly what props we provide on each page */}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...pageProps} />
      </main>
    </>
  );
}

export default appWithTranslation(App);
